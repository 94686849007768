import React, { useState } from "react";
import "./MultiStepForm.css";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    question1: "",
    question2: "",
    question3: "",
    firstName: "",
    lastName: "",
    city: "",
    email: "",
    phone: "",
    consent: false,
  });

  const handleNext = () => {
    if (step < 4) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    alert("Thank you for submitting the form!");
  };

  return (
<div className="form-box">
   {/* Call to Action Section */}
   <section className="service-call-to-action">
        <h2>Ready to Start Your Home Remodel?</h2>
        <p>Don’t have time to call?
        Submit the following form and we’ll call you!</p>
        <a href="/contact" className="category-buttons"><button>Get Started</button> </a>
      </section>
<div className="form-container">
      <h1>Get Design/Build Consultation</h1>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="form-step">
            <h2>What would you like answers to?</h2>
            <div className="options">
              {[
                "How much will it cost?",
                "How long will it take?",
                "Are my ideas even possible?",
                "What about permits?",
                "Where do I go to get the design?",
                "Other",
              ].map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name="question1"
                    value={option}
                    checked={formData.question1 === option}
                    onChange={handleChange}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <h2>What are you planning?</h2>
            <div className="options">
              {[
                "Kitchen Remodeling",
                "Home Remodeling",
                "Bathroom Remodeling",
                "New Construction",
                "Hardscaping (outdoor)",
                "Other",
              ].map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name="question2"
                    value={option}
                    checked={formData.question2 === option}
                    onChange={handleChange}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <h2>What stage are you in?</h2>
            <div className="options">
              {[
                "Early Discovery Stage",
                "Have a Good Idea of What We Want",
                "Looking for a Contractor to Hire",
              ].map((option) => (
                <label key={option}>
                  <input
                    type="radio"
                    name="question3"
                    value={option}
                    checked={formData.question3 === option}
                    onChange={handleChange}
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="form-step">
            <h2>How can we reach you?</h2>
            <div className="form-group">
              <label>
                First Name*
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Last Name*
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                City*
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email*
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Phone*
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </label>
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  required
                />
                I give my consent to receiving texts from you to facilitate our
                communication. You can reply STOP to opt out at any time. If
                this is not acceptable, PLEASE CALL INSTEAD. We do not share or
                sell your information!
              </label>
              <p className="privacy-policy">
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
            </div>
          </div>
        )}

        {/* Navigation Buttons */}
        <div className="form-navigation">
          {step > 1 && <span className="category-buttons"><button type="button" onClick={handleBack}>Back</button></span>}
          {step < 4 &&  <span className="category-buttons"> <button type="button" onClick={handleNext}>Next</button></span>}
          {step === 4 &&  <span className="category-buttons"> <button type="submit">Submit</button></span>}
        </div>
      </form>
    </div>
</div>
    
    
  );
};

export default MultiStepForm;
