import React from 'react'
import Home from './Home'
import Blog from './component/Blog/Blogs'
import Contact from './Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Work from './Work';
import { Error } from './Error';
import Services from './Services';
import ServiceDetails from './ServiceDetails';
import ScrollToTop from './component/ScrollToTop';
import BlogDetailPage from './component/Blog/BlogDetailPage';
import FAQPage from './component/Faq/FAQPage';
import ReviewPage from './component/Review/ReviewPage';
import FeaturedProject from './component/FeatureProject/FeaturedProject';
import AboutUs from './pages/About Us/AboutUs';
import Locations from './component/Locations/Locations';
import LocationDetailPage from './component/Locations/LocationDetailPage';
import Finance from './component/Finance/Finance';
import BuildArchitecture from './component/ServicePage/BuildArchitecture';
import HomeRemodeling from './component/ServicePage/HomeRemodeling';
import KitchenRemodeling from './component/ServicePage/KitchenRemodeling';
import BathroomRemodeling from './component/ServicePage/BathroomRemodeling';
import RoomAdditions from './component/ServicePage/RoomAdditions';
import GarageConversions from './component/ServicePage/GarageConversions';
import ADU from './component/ServicePage/ADU';
import Landscaping from './component/ServicePage/Landscaping';
import Roofing from './component/ServicePage/Roofing';
import ConcreteDriveways from './component/ServicePage/ConcreteDriveways';
import NewHomeConstruction from './component/ServicePage/NewHomeConstruction';
import ExteriorInterior from './component/ServicePage/ExteriorInterior';
import WindowDoorInstallation from './component/ServicePage/WindowDoorInstallation';
import OutdoorPatioRemodeling from './component/ServicePage/OutdoorPatioRemodeling';
import LocationProvider from './component/Locations/LocationContext';
import ScrollToTopOnRouteChange from './component/ScrollToTopOnRouteChange';




const App = () => {

  return (
   <BrowserRouter>
   <ScrollToTopOnRouteChange />
   <ScrollToTop/>
      <Header />     
      <LocationProvider>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path='/services/:href' element={<Services />} />
            <Route path="/home-remodeling" element={<HomeRemodeling />} />
            <Route path="/kitchen-remodeling" element={<KitchenRemodeling />} />
            <Route path="/bathroom-remodeling" element={<BathroomRemodeling  />} />
            <Route path="/room-additions" element={<RoomAdditions />} />
            <Route path="/garage-conversions" element={<GarageConversions  />} />
            <Route path="/adu" element={<ADU  />} />
            <Route path="/roofing" element={<Roofing  />} />
            <Route path="/landscaping" element={<Landscaping />} />
            <Route path="/concrete-driveways" element={<ConcreteDriveways />} />
            <Route path="/new-home-construction" element={<NewHomeConstruction />} />
            <Route path="/exterior-interior-painting" element={<ExteriorInterior />} />
            <Route path="/window-door-installation" element={<WindowDoorInstallation />} />
            <Route path="/3d-build" element={<BuildArchitecture />} />
            <Route path="/outdoor-patio-remodeling" element={<OutdoorPatioRemodeling />} />
           

            <Route path="/work" element={<Work />} />
            <Route path="/blog" element={<Blog />} />
            {/* <Route path="/located" element={<LocationDetailPage location={locationData} />} /> */}
            
            <Route path="/blog/:slug" element={<BlogDetailPage />} />
            <Route path="/reviews" element={<ReviewPage />} />
            <Route path="/project" element={<FeaturedProject />} />
            <Route path="/finance" element={<Finance />} />
            <Route path='/faq' element={<FAQPage />} />
            <Route path="/locations" element={<Locations/>} />
            <Route path="/locations/:locationName" element={<LocationDetailPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Error />} />
        </Routes>      
         </LocationProvider>
      <Footer />
   </BrowserRouter>
  )
}

export default App;