import "./ServicePage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import Questions from "../Faq/Questions";
import { Link, useNavigate } from "react-router-dom";

const BathroomRemodeling = () => {

  const navigate = useNavigate();
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
  {
      title: "New Home Construction",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/7.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/new-home-construction"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];

  return (
    <div className="service-page">
      {/* Back Button */}
      <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      <header className="service-header">
        <img
          src="https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/Banner.jpg"
          alt="Beautifully Remodeled Bathroom"
          className="header-image"
        />
        <h1>Bathroom Remodeling Services</h1>
        <p>Transform your bathroom into a luxurious retreat!
        </p>
      </header>

      <section className="service-introduction">
        <div className="section-content">
          <img
            src="https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/Why-Choose.jpg"
            alt="Introduction to Bathroom Remodeling"
            className="section-image"
          />
          <div className="serve-text">
            <h2>Why Choose Our Bathroom Remodeling Services?
            </h2>
            <p>
            We at Level Contractor understand that every home is unique and so are their needs and requirements. That’s why we take pride in transforming their dull spaces like bathrooms into aesthetically appealing and more functional ones. Whether you want to add a luxurious spa-like retreat, a sleek modern design, or a classic, timeless space to your bathroom, we are here to help you. Our team of experienced professionals who are versed and experienced in the remodelling and construction industry are here to  make your vision turn to reality. 
             </p>
          </div>

        </div>
      </section>

      {/* Benefits Section with Different Style */}
      <section className="bathroom-remodeling">
        <h2>Benefits of Bathroom Remodeling</h2>
        <div className="benefits-grid">
          {[
            { title: "Enhanced Comfort", description: "Our strong commitment and focus towards our works ensures you in making your home more visually appealing, aesthetic and comfortable." },
            { title: "Increased Home Value", description: " From kitchen upgrades to bathroom transformations, our work not only improves your daily living but also boosts your property’s worth." },
            { title: "Visually Appealing designs", description: "Create a home that reflects your personal taste and style. We work with you to design spaces that are both beautiful and functional, ensuring every room feels fresh and inviting. " },
            { title: "Sustainability", description: "We incorporate energy-efficient solutions and eco-friendly materials to reduce your home’s environmental impact and help you save on long-term utility costs." },
            { title: "Personalized Design", description: " Our team will work closely with you to bring your dream home to life with custom designs and personalized touches that suit your style." }
            // { title: "Improved Aesthetics", description: "Create a stylish and inviting atmosphere." }
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <span className="benefit-icon">{benefit.icon}</span>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Service Highlights Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaHammer size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>

      {/* Process Section with Different Style */}
      <section className="service-process">
        <h2>Our Remodeling Process</h2>
        <div className="process-steps">
          {[
            { step: "Consultation", description: "We discuss your vision, needs, and budget." },
            { step: "Design", description: "Our designers create a customized plan for your home." },
            { step: "Material Selection", description: "Choose from high-quality materials and finishes." },
            { step: "Construction", description: "Our skilled team executes the remodel with precision." },
            { step: "Final Walkthrough", description: "We ensure everything meets your expectations before completion." }
          ].map((process, index) => (
            <div key={index} className="process-card">
              <span className="process-icon">🔨</span> {/* Icon for process steps */}
              <div className="process-content">
                <h3>{process.step}</h3>
                <p>{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* New Work Showcase Section */}
      <section className="work-showcase">
        <h2>Our Work Showcase</h2>

        {/* Before Remodeling */}
        <div className="showcase-section">
          <h3>Before Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/Before-${index + 1}.jpg`} alt={`Before Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* In Progress */}
        <div className="showcase-section">
          <h3>In Progress</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/WIP-${index + 1}.jpg`} alt={`In Progress Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* After Remodeling */}
        <div className="showcase-section">
          <h3>After Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/After-${index + 1}.jpg`} alt={`After Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

      </section>

      {/* FAQ Section */}
<Questions />

      <MultiStepForm />

                 {/* Other Services Section */}
      <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default BathroomRemodeling;
