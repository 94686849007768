import React, { useEffect, useRef } from "react";
import BannerSlider from "./component/Banner/BannerSlider";
import Testimonials from "./component/TestimonialsSlider/Testimonials";
import HomeService from "./component/HomeService";
import ServiceList from "./component/ServiceList";
import HomeWork from "./component/HomeWork";
import HomeContact from "./component/HomeContact";

const Home = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    // Apply transition effect for background color
    document.body.style.transition = "background-color 0.6s ease-out";

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const newColor = entry.target.getAttribute("data-color");
            if (newColor) {
              document.body.style.backgroundColor = newColor;
            }
          }
        });
      },
      {
        threshold: 0, // Trigger as soon as a section enters or exits the viewport
        rootMargin: "0px 0px -80% 0px", // Adjusts for early transitions
      }
    );

    // Observe each section
    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // Cleanup observer and reset background
      observer.disconnect();
      document.body.style.backgroundColor = ""; // Reset background color
      document.body.style.transition = ""; // Reset transition
    };
  }, []);

  return (
    <div id="qodef-page-outer">
      <div id="qodef-page-inner" className="qodef-content-full-width">
        <main
          id="qodef-page-content"
          className="qodef-grid qodef-layout--template"
          role="main"
        >
          <div className="qodef-grid-inner clear">
            <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
              <div className="elementor elementor-1303">
                {/* Banner Section */}
                <section
                  data-color="#B3B3B3"
                  className="elementor-element elementor-element-fbc184f elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  ref={(el) => (sectionsRef.current[0] = el)}
                >
                  <BannerSlider />
                </section>

                {/* Home Services */}
                <section
                  data-color="#848484"
                  className="elementor-section elementor-top-section elementor-element elementor-element-309e9e6 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-elementor-content-no qodef-elementor-column-no"
                  ref={(el) => (sectionsRef.current[1] = el)}
                >
                  <HomeService />
                </section>

                {/* Service List */}
                <section
                  data-color="#676767"
                  className="elementor-section elementor-top-section elementor-element elementor-element-5192a63 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-elementor-content-no qodef-elementor-column-no mid-main-sec"
                  ref={(el) => (sectionsRef.current[2] = el)}
                >
                  <ServiceList />
                </section>

                {/* Work Showcase */}
                <section
                  data-color="#676767"
                  className="elementor-section elementor-top-section elementor-element elementor-element-497a2c7 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-elementor-content-no qodef-elementor-column-no"
                  ref={(el) => (sectionsRef.current[3] = el)}
                >
                  <HomeWork />
                </section>

                {/* Testimonials */}
                <section
                  data-color="#B3B3B3"
                  className="elementor-section elementor-top-section elementor-element elementor-element-4e75dd2 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-elementor-content-no qodef-elementor-column-no"
                  ref={(el) => (sectionsRef.current[4] = el)}
                >
                  <Testimonials />
                </section>

                {/* Contact Section */}
                <section
                  data-color="#f4f3ef"
                  className="elementor-section elementor-top-section elementor-element elementor-element-4e75dd2 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-elementor-content-no qodef-elementor-column-no"
                  ref={(el) => (sectionsRef.current[5] = el)}
                >
                  <HomeContact />
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;
