import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const HomeService = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch the data from your backend API
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/home-services-api.php')
      .then(response => response.json())
      .then(data => setServices(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="elementor-container elementor-column-gap-default">
      <div className="elementor-column">
        <div className="elementor-widget-wrap">
          <div className="elementor-element elementor-element-73bb9fc elementor-widget-tablet__width-auto elementor-widget elementor-widget-level_core_portfolio_list" data-id="73bb9fc" data-element_type="widget" data-widget_type="level_core_portfolio_list.default">
            <div className="elementor-widget-container">
              <div className="qodef-shortcode qodef-m qodef-portfolio-list qodef-gutter--tiny qodef-col-num--2 qodef-item-layout--info-below qodef--no-bottom-space qodef-pagination--off qodef-responsive--custom qodef-col-num--1440--2 qodef-col-num--1366--2 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1 qodef-hover-animation--move-text">
                <div className="qodef-grid-inner clear home-ser-box">
                  {services.map(service => (
                    <article key={service.id} className="qodef-e qodef-grid-item qodef-item--full portfolio-item">
                      <div className="qodef-e-inner home-ser">
                        <div className="qodef-e-image">
                          <div className="qodef-e-media-image">
                            <Link to={`/services/${service.href_link}`}>
                              <img
                                width={1100}
                                height={1545}
                                src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${service.image}`}
                                className="attachment-full size-full wp-post-image"
                                alt={service.hser_heading}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="qodef-e-content">
                          <div className="qodef-e-text">
                            <h5 itemProp="name" className="qodef-e-title entry-title">
                              <Link className="qodef-e-title-link" to={`/services/${service.href_link}`}>
                                {service.hser_heading}
                              </Link>
                            </h5>
                          </div>
                          <div className="qodef-e-top-holder">
                            <div className="qodef-e-info">
                              <Link to={`/services/${service.href_link}`} rel="tag">
                                Details
                              </Link>
                              <div className="qodef-info-separator-end"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeService;
