import React, { useState } from 'react';
import './Locations.css';
import { Link } from 'react-router-dom';

const Locations = () => {
  const locationsList = [
    "Atherton", "Blackhawk", "Belmont", "Burlingame", "Brentwood", "Castro Valley",
    "Campbell", "Cupertino", "Concord", "Clayton", "Danville", "Dublin", "Discovery Bay",
    "Fremont", "Foster City", "Hayward", "Livermore", "Lafayette", "Los Gatos", "Los Altos",
    "Moraga", "Milpitas", "Mountain View", "Menlo Park", "Newark", "Orinda", "Pleasanton",
    "Pleasant Hill", "Palo Alto", "Redwood City", "San Ramon", "San Lorenzo", "San Jose",
    "Santa Clara", "Saratoga", "Sunnyvale", "San Carlos", "San Mateo", "Walnut Creek", "Woodside"
  ];

  const [locations, setLocations] = useState(locationsList.slice(0, 9));
  const [hasMore, setHasMore] = useState(true);

  const loadMoreLocations = () => {
    const nextLocations = locationsList.slice(locations.length, locations.length + 35);
    setLocations([...locations, ...nextLocations]);
    if (locations.length + nextLocations.length >= locationsList.length) {
      setHasMore(false);
    }
  };

  return (
    <div className="locations-container">
      <h1 className="locations-heading">Locations We Serve</h1>
      <p className="locations-description">
        Below is a list of locations where we proudly serve, and an interactive map to help visualize our service area.
      </p>

      {/* List of Locations */}
      <div className="locations-list">
        <h2 className="locations-subheading">Our Service Areas:</h2>
        <ul className="locations-ul">
          {locations.map((location, index) => (
            
              <Link key={index} to={`/locations/${location.toLowerCase().replace(/ /g, "-")}`}>
               <li  className="locations-item"> {location} </li>
              </Link>
           
          ))}
        </ul>
        {/* Load More Button */}
        {hasMore && (
          <button className="load-more" onClick={loadMoreLocations}>
            Load More
          </button>
        )}
      </div>



      {/* Static Map Section */}
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1dBEVlkJoyMIKz1lLLvhmBpSkbUQWw0c&ehbc=2E312F"
          width="100%"
          height="480"
          style={{ border: 'none' }}
          title="Static Map"
        ></iframe>
      </div>
    </div>
  );
};

export default Locations;
