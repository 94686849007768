import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://level-contractor.thepreview.pro///Backend/admin/api/blogs-api.php');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <div className="ser-first elementor-top-section elementor-element elementor-section-full_width elementor-section-height-default">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-column elementor-col-100">
            <div className="elementor-widget-wrap" style={{ textAlign: "center" }}>
              <h1>Our Blog</h1>
            </div>
          </div>
        </div>
      </div>
      {Array.isArray(blogs) && blogs.map((blog) => (
        <div className="blog-section" key={blog.id}>
          {/* Link to blog detail page using the slug */}
          <Link to={`/blog/${blog.slug}`} className="blog-image-container">
            <img 
              src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${blog.image}`} 
              alt={blog.title} 
              className="blog-image" 
            />
          </Link>
          <div className="blog-content">
            <Link to={`/blog/${blog.slug}`} className="blog-title-link">
              <h2 className="blog-title">{blog.title}</h2>
            </Link>
            <div className="blog-meta">
              <span className="blog-category">{blog.category}</span> |
              <span className="blog-date">{new Date(blog.created_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long', // Use '2-digit' for numeric month
    day: '2-digit'
  })}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Blog;
