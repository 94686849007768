import "./FaqPage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import Questions from "./Questions.jsx";

const FAQPage = () => {


  return (
    <div className="faq-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Frequently Asked Questions</h1>
          <p>Your questions, answered by our experts.</p>
        </div>
      </section>
 
      {/* FAQ Section */}
    <Questions />
       {/* Call to Action Section */}
            {/* <section className="cta-section">
              <h2>Ready to Start Your Project?</h2>
              <p>Contact us today for a free consultation and let’s build something beautiful together.</p>
              <a href="" className="category-buttons">
              <button className="cta-button">Get a Free Quote</button>
              </a>
            </section> */}
            
            <MultiStepForm />

    </div>
  );
};

export default FAQPage;
