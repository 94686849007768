import "./ServicePage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import Questions from "../Faq/Questions";
import { Link, useNavigate } from "react-router-dom";

const Roofing = () => {

  const navigate = useNavigate();
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "New Home Construction",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/7.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/new-home-construction"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];
  return (
    <div className="service-page">
      {/* Back Button */}
      <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      <header className="service-header">
        <img
          src="https://level-contractor.thepreview.pro/images/services/roofing/banner.jpg"
          alt="Beautifully Remodeled Bathroom"
          className="header-image"
        />
        <h1>Roofing Services</h1>
        <p>Protect your home with a roof that stands the test of time!       </p>
      </header>

      <section className="service-introduction">
        <div className="section-content">
          <img
            src="https://level-contractor.thepreview.pro/images/services/roofing/why-choose.jpg"
            alt="Introduction to Bathroom Remodeling"
            className="section-image"
          />
          <div className="serve-text">
            <h2>Why Choose Our Roofing Services?</h2>
            <p>
            We at Level Contractor, understand the importance of a strong, reliable roof in protecting your home and loved ones. Whether you're in need of a roof replacement, repair, or new installation, our expert team is committed to delivering high-quality, durable solutions tailored to your specific needs. We use only the finest materials, and our skilled professionals ensure that every project is completed with precision, care, and attention to detail. 
            </p>
          </div>

        </div>
      </section>

      {/* Benefits Section with Different Style */}
      <section className="roofing">
        <h2>Benefits of Our Roofing Services</h2>
        <div className="benefits-grid">
          {[
            { title: "Reliable Protection", description: "Our roofing services provide reliable protection against the harshest weather conditions. With top-quality materials and expert installation, we ensure your home stays safe and secure for years to come. " },
            { title: "Cost-Effective", description: "We offer competitive pricing for both repairs and installations, helping you protect your home without breaking the bank. A new roof or repair can save you money on long-term repairs and energy costs." },
            { title: "Energy Efficiency", description: "We provide energy-efficient roofing options that help reduce your utility bills, keeping your home cooler in the summer and warmer in the winter, while also contributing to environmental sustainability." },
            { title: "Visually Appealing designs", description: "Our roofs are built to stand the test of time. We use only the highest-quality materials, ensuring your roof is strong, resilient, and low-maintenance for years." },
            { title: "Customizable Options", description: "We offer a wide range of styles, colors, and materials to match your home's aesthetic. Our roofing solutions are tailored to meet both your functional needs and design preferences." }
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <span className="benefit-icon">{benefit.icon}</span>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Service Highlights Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaHammer size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>

      {/* Process Section with Different Style */}
      <section className="service-process">
        <h2>Our Roofing Process</h2>
        <div className="process-steps">
          {[
            { step: "Consultation", description: "We discuss your vision, needs, and budget." },
            { step: "Design", description: "Our designers create a customized plan for your home." },
            { step: "Material Selection", description: "Choose from high-quality materials and finishes." },
            { step: "Construction", description: "Our skilled team executes the remodel with precision." },
            { step: "Final Walkthrough", description: "We ensure everything meets your expectations before completion." }
          ].map((process, index) => (
            <div key={index} className="process-card">
              <span className="process-icon">🔨</span> {/* Icon for process steps */}
              <div className="process-content">
                <h3>{process.step}</h3>
                <p>{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* New Work Showcase Section */}
      <section className="work-showcase">
        <h2>Our Work Showcase</h2>

        {/* Before Remodeling */}
        <div className="showcase-section">
          <h3>Before Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/roofing/Before-${index + 1}.jpg`} alt={`Before Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* In Progress */}
        <div className="showcase-section">
          <h3>In Progress</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/roofing/WIP-${index + 1}.jpg`} alt={`In Progress Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* After Remodeling */}
        <div className="showcase-section">
          <h3>After Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/roofing/After-${index + 1}.jpg`} alt={`After Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

      </section>

      {/* FAQ Section */}
      <Questions />

      <MultiStepForm />
     {/* Other Services Section */}
     <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Roofing;
