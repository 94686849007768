import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const HomeWork = () => {
  const [works, setWorks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        const response = await fetch(
          "https://level-contractor.thepreview.pro/Backend/admin/api/home-work-api.php"
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setWorks(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchWorks();
  }, []);
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <div>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-column elementor-col-100 elementor-top-column">
          <div className=" work-sec-pd">
            <div className="elementor-widget-container">
              <div className="heading-our-work">
                <h3>Our Works</h3>
              </div>
              <div className="qodef-grid-inner clear work-sec-box qodef-hover-animation--move-text qodef-gutter--tiny qodef-col-num--2 qodef-item-layout--info-below qodef--no-bottom-space qodef-pagination--off qodef-responsive--custom qodef-col-num--1440--2 qodef-col-num--1366--2 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1">
                {works.map((work, index) => (
                  <article key={work.id} className="qodef-e qodef-grid-item">
                    <div className="qodef-e-inner wbox-home">
                      <div className="qodef-e-image">
                        <div className="qodef-e-media-image">
                          <Link to="/work">
                            <img
                              src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${work.image}`} // Adjust the URL as needed
                              className="attachment-full size-full wp-post-image"
                              alt={work.hwork_heading} // Use the actual heading for alt text
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="qodef-e-content">
                        <div className="qodef-e-text">
                          <h5 className="qodef-e-title entry-title">
                            <Link to="/work" className="qodef-e-title-link">
                              {work.hwork_heading}
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
              <div className="category-buttons work-btn">
                <Link to="/work">
                  <button>See More Work</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWork;
