import "./ServicePage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import Questions from "../Faq/Questions";
import { Link, useNavigate } from "react-router-dom";

const OutdoorPatioRemodeling = () => {


  const navigate = useNavigate();
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Exterior & Interior Painting",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/7.webp",
      link: "https://level-contractor.thepreview.pro/services/exterior-interior-painting"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];
  return (
    <div className="service-page">
      {/* Back Button */}
      <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      <header className="service-header">
        <img
          src="https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/banner.jpg"
          alt="Beautifully Remodeled Bathroom"
          className="header-image"
        />
        <h1>Outdoor Patio Remodeling Services</h1>
        <p>Transform Your Outdoors, Elevate Your Living with Level Contractor!
        </p>
      </header>

      <section className="service-introduction">
        <div className="section-content">
          <img
            src="https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/why-choose.jpg"
            alt="Introduction to New Home Construction"
            className="section-image"
          />
          <div className="serve-text">
            <h3>Why Choose Our Outdoor Patio Remodeling   Services?</h3>
            <p>
            At Level Contractor, we specialize in transforming outdoor spaces into stunning, functional patios that enhance your lifestyle. Our team combines creativity, craftsmanship, and high-quality materials to design outdoor areas that reflect your unique style. We prioritize your vision, ensuring every detail is customized to suit your needs, whether for relaxation, entertainment, or family gatherings. With our expertise in design, construction, and installation, we deliver exceptional results that increase your outdoor living space’s comfort and value. Choose us for a seamless and stress-free patio remodeling experience that will exceed your expectations.

            </p>
          </div>

        </div>
      </section>

      {/* Benefits Section with Different Style */}
      <section className="outdoor-patio-remodeling">
        <h2>Benefits of Outdoor Patio Remodeling</h2>
        <div className="benefits-grid">
          {[
            { title: "Durability", description: "We use premium materials and expert craftsmanship to create outdoor spaces that withstand the elements, ensuring your patio remains beautiful and functional for years to come." },
            { title: "Customizable Options", description: " From cozy retreats to modern, luxurious designs, we offer a wide range of customization options to match your style and enhance your outdoor living space." },
            { title: "Cost-Effective", description: "We provide exceptional value by delivering durable, low-maintenance solutions that not only enhance your outdoor space but also increase your home’s overall appeal." },
            { title: " Reliable & Efficient", description: "We pride ourselves on completing projects on time, with minimal disruption, while delivering results that surpass your expectations." },
            { title: "Expert Installation", description: " Our skilled team ensures precise installation, creating a well-crafted, durable patio that becomes a true extension of your home." }
            // { title: "Improved Aesthetics", description: "Create a stylish and inviting atmosphere." }
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <span className="benefit-icon">{benefit.icon}</span>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Service Highlights Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaHammer size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>

      {/* Process Section with Different Style */}
      <section className="service-process">
        <h2>Our Outdoor Patio Remodeling Process</h2>
        <div className="process-steps">
          {[
            { step: "Consultation", description: "We discuss your vision, needs, and budget." },
            { step: "Design", description: "Our designers create a customized plan for your home." },
            { step: "Material Selection", description: "Choose from high-quality materials and finishes." },
            { step: "Construction", description: "Our skilled team executes the remodel with precision." },
            { step: "Final Walkthrough", description: "We ensure everything meets your expectations before completion." }
          ].map((process, index) => (
            <div key={index} className="process-card">
              <span className="process-icon">🔨</span> {/* Icon for process steps */}
              <div className="process-content">
                <h3>{process.step}</h3>
                <p>{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* New Work Showcase Section */}
      <section className="work-showcase">
        <h2>Our Work Showcase</h2>

        {/* Before Remodeling */}
        <div className="showcase-section">
          <h3>Before Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/Before-${index + 1}.jpg`} alt={`Before Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* In Progress */}
        <div className="showcase-section">
          <h3>In Progress</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/WIP-${index + 1}.jpg`} alt={`In Progress Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* After Remodeling */}
        <div className="showcase-section">
          <h3>After Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/After-${index + 1}.jpg`} alt={`After Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

      </section>

      {/* FAQ Section */}
      <Questions />

      <MultiStepForm />
                 {/* Other Services Section */}
      <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default OutdoorPatioRemodeling;
