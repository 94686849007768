import React, { useState, useEffect } from 'react';
import './BannerSlider.css'; // Ensure this CSS file exists and is correctly referenced
import { Link } from 'react-router-dom';

const BannerSlider = () => {
  const [currentImage, setCurrentImage] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [banners, setBanners] = useState([]);
  const [preloadedImages, setPreloadedImages] = useState({}); // Store preloaded images

  const placeholderImage = 'https://level-contractor.thepreview.pro/images/rjtgjfjfjgf.webp';

  // Fetch banners data from the backend API
  useEffect(() => {
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/banner-api.php')
      .then((response) => response.json())
      .then((data) => {
        setBanners(data);
        if (data.length > 0) {
          preloadImages(data); // Preload images once data is fetched
          setCurrentImage(`https://level-contractor.thepreview.pro/images/rjtgjfjfjgf.webp`);
        }
      })
      .catch((error) => {
        console.error('Error fetching banners:', error);
      });
  }, []);

  // Preload all images to make them available in the cache
  const preloadImages = (bannersData) => {
    const images = {};
    bannersData.forEach((banner) => {
      const img = new Image();
      img.src = `https://level-contractor.thepreview.pro//Backend/admin/uploads/${banner.banner_image}`;
      images[banner.banner_image] = img;
    });
    setPreloadedImages(images); // Store the preloaded images in state
  };

  const handleMouseEnter = (index) => {
    if (banners[index] && banners[index].banner_image) {
      setIsAnimating(true);
      setCurrentImage(preloadedImages[banners[index].banner_image]?.src || placeholderImage); // Use preloaded image
  
      // Update active class
      document.querySelectorAll('.header-item').forEach((item, idx) => {
        item.classList.toggle('active', idx === index);
      });
    }
  };
  
  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 1000); // Match this duration to your animation duration
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);

  return (
    <div className="banner-container">
          <div className="header-list">
            {banners.map((banner, index) => (
              <div
                key={index}
                className="header-item"
                onMouseEnter={() => handleMouseEnter(index)}
                onClick={() => handleMouseEnter(index)}>
                {banner.banner_heading}
              </div>
            ))}
          </div>
          <div className={`banner-image ${isAnimating ? 'animate' : ''}`}>
            <Link to="/services">
              <img 
                src={currentImage || placeholderImage} 
                alt="Banner" 
                onError={(e) => e.target.src = placeholderImage} // Fallback to placeholder on error
                loading="eager" // Ensure the image is not lazily loaded on initial display
              />
            </Link>
          </div>
    </div>
  );
};

export default BannerSlider;
