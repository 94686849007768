import React from "react";
import "./FeaturedProject.css"; // Ensure to style it using this CSS file or integrate styling in your app
import { Link } from "react-router-dom";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import Questions from "../Faq/Questions";

const FeaturedProject = () => {
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];

  return (
    <div className="featured-project-page">
      {/* Featured Project Section */}
      <div className="featured-project-section">
      <section className="featured-project">
        <div className="project-details">
          <h2>New Home Construction</h2>
          <h3>San Ramon, CA</h3>
          <p>
          At Level Contractor, we have a diverse portfolio of projects. Under which each one project showcases our dedication to quality, and client satisfaction. Whether it's a simple remodel or a large-scale custom build, every project is approached with the same dedication to excellence. 
          </p>
          <a className="category-buttons" href="/contact">
          <button >Get a Quote</button>
          </a>
        </div>
        <div className="project-video">
          <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/Home-Construction.webp" alt="Home Construction" />
        </div>
      </section>

      {/* Gallery Section */}
      <section className="project-gallery">
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/1.jpg" alt="Project 1" />
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/2.jpg" alt="Project 2" />
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/3.jpg" alt="Project 3" />
      </section>
    </div>


    <div className="featured-project-section">   
      <section className="featured-project">
        <div className="project-details">
          <h2>Home Remodeling – Modern Family Home</h2>
          <h3>San Carlos, CA</h3>
          <p>
          This innovative Home Remodelling has been transformed from an outdated house into a modern, spacious family home. We opened up the layout to create a seamless flow, added energy-efficient features, and used high-end materials to create a stylish and functional space.
          </p>
          <a className="category-buttons" href="/contact">
          <button>Get a Quote</button></a>
        </div>
        <div className="project-video">
         <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/New-Home-Construction.jpg" alt="New Home Construction" />
        </div>
      </section>

      {/* Gallery Section */}
      <section className="project-gallery">
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/4.jpg" alt="Project 1" />
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/5.jpg" alt="Project 2" />
        <img src="https://level-contractor.thepreview.pro/images/project-page-services/project-detail/6.jpg" alt="Project 3" />
      </section>
       </div>

       <Questions />

       <MultiStepForm />
      {/* Other Services Section */}
      <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FeaturedProject;
