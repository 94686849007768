import React from 'react';
import './Finance.css'; // Ensure to create this CSS file for styling

const financeOptions = [
    {
        name: 'HERO',
        description:
            'The HERO Program is an energy-efficient financing program in the United States, offering Home Energy Renovation Opportunity (HERO) financing for energy-efficient, water-efficient, and renewable energy products.',
        logo: 'https://baybuilders.com/wp-content/uploads/2023/02/hero_finance.png',
    },
    {
        name: 'Ygrene',
        description:
            'Ygrenes award-winning PACE program provides 100 percent no money down financing to help property owners make cost-effective decisions towards energy efficiency and renewable energy.',
        logo: 'https://baybuilders.com/wp-content/uploads/2023/02/ygrene_400_finance.png',
    },
    {
        name: 'Benji',
        description:
            'Benji offers unsecured financing for home improvement projects through participating contractors and loans from financial institutions.',
        logo: 'https://baybuilders.com/wp-content/uploads/2023/02/Benji_finance.png',
    },
    {
        name: 'CaliforniaFIRST',
        description:
            'CaliforniaFIRST allows property owners to finance the installation of energy and water improvements for residential and commercial properties.',
        logo: 'https://baybuilders.com/wp-content/uploads/2023/02/california_first_400_finance.png',
    },
];

const Finance = () => {
    return (
        <div className="finance-container">
            <h1 className="finance-heading">FINANCE</h1>
            <p className="finance-description">
                Below are a few financing options to help you get started.
            </p>
            <div className="finance-options">
                {financeOptions.map((option, index) => (
                    <div key={index} className="finance-card">
                        <img src={option.logo} alt={`${option.name} logo`} className="finance-logo" />
                        {/* <h2 className="finance-name">{option.name}</h2> */}
                        <p className="finance-text">{option.description}</p>
                    </div>
                ))}
            </div>
            <div className="consultation-section">
                <h2>Request Complimentary Consultation</h2>
                <p>
                    We’ll meet either by phone or on location. Call <a href="tel:8182972920">
                    <strong> +1 818-297-2920</strong></a> or submit the following form, and
                    we’ll call you to schedule a meeting.
                </p>
            </div>
        </div>
    );
};

export default Finance;
