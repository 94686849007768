import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/testimonials-api.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.error) {
          setError(data.error);
        } else {
          setTestimonials(data);
          setError(null);
        }
      })
      .catch(error => {
        setError(error.message);
        console.error('Error fetching data:', error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="testimonials-container">
      <h2>What Our Clients Are Saying
      </h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <div className="testimonial-image">
              <img src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${testimonial.image}`} alt={testimonial.name} />
            </div>
            <div className="testimonial-content">
              <h3>{testimonial.name}</h3>
              <p className="testimonial-title">{testimonial.designation}</p>
              <p className="testimonial-text">{testimonial.content}</p>
              <img src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${testimonial.signimg}`} alt="Signature" className="testimonial-signature" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
