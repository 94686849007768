import React, {useState} from 'react'
import Testimonials from '../TestimonialsSlider/Testimonials';



const Questions = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
      {
        question: "What services do you offer?",
        answer:
          "We offer a full range of remodeling and construction services including kitchen and bathroom remodels, home additions, ADUs, garage conversions, roofing, landscaping, window and door installation, and much more. Whatever your project, we’re here to help! ",
      },
      {
        question: "How long will my remodeling project take?",
        answer:
          "Your project's timeline depends on the work's size and complexity. Smaller projects like bathroom or kitchen remodels may take a few weeks, while larger projects such as home additions or garage conversions can take several months. We'll provide a clear timeline during your initial consultation.",
      },
      {
        question: "How much will my project cost?",
        answer:
          "The cost of your project depends on the scope and materials used. After a consultation, we’ll provide a detailed estimate to clearly understand the cost. We work with you to stay within your budget while delivering quality results.",
      },
      {
        question: "Are you licensed and insured?",
        answer:
          "Yes, we're fully licensed and insured. We meet all the industry’s safety standards and regulations, ensuring that your project is completed to the highest quality and safety.",
      },
      {
        question: "Do I need a permit for my remodeling project?",
        answer:
          "Major remodeling projects usually require permits, such as home additions, roofing, and certain painting jobs. We handle all the necessary permits and ensure your project complies with local building codes.",
      },
    ];
  
    const toggleFAQ = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };


  return (
    <>
          <section className="faq-section">
        <h2>Common Questions</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <h3>{faq.question}</h3>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </div>
              {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
            </div>
          ))}
        </div>
      </section>
      <Testimonials />
    </>
  )
}

export default Questions;