import "./AboutUs.css";
import MultiStepForm from "../../component/MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import Questions from "../../component/Faq/Questions";
import Locations from "../../component/Locations/Locations"
import { Link } from "react-router-dom";

const AboutUs = () => {
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];
  return (

    <div className="about-us">  
      {/* Hero Section */}
      <section className="about-section">
        <div className="about-text">
          <h1>About Level Contractor</h1>
          <p>
          We at Level Contractor specialize in transforming your vision into reality with the implementation of our exceptional remodeling and construction services. Since 2003, we have been committed to loyalty, dignity, and honesty in every project we undertake. From the initial conceptualization to its completion, we commit to our top-quality work, offering you a smooth and enjoyable journey. Rely on Level Contractor to improve your living environment through exceptional and stunning designs.</p>
        </div>
        <div className="about-image">
          <img
            src="https://level-contractor.thepreview.pro/images/about-us/about.webp"
            alt="Level Contractor Team"
          />
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="mission-section">
        <h2>Our Mission & Vision</h2>
        <p>
        At Level Contractor, our mission is to offer our client’s with the stunning remodeling and construction services that exceed their expectations. Our ultimate aim is to design functional and beautiful spaces to meet the unique needs of our clients in the best possible way. 
        </p>
        <div className="mission-images">
          <img
            src="https://level-contractor.thepreview.pro/images/about-us/1.webp"
            alt="Mission 1"
          />
          <img
            src="https://level-contractor.thepreview.pro/images/about-us/2.webp"
            alt="Mission 2"
          />
        </div>
      </section>

    {/* Service Highlights Section */}
          <section className="highlights-section abt-highlight">
            <h2>Why Work With Us</h2>
            <div className="highlights-grid">
              <div className="highlight">
                <FaHammer size={40} />
                <h3>Expert Craftsmanship</h3>
                <p>Precision and quality in every detail.</p>
              </div>
              <div className="highlight">
                <FaTools size={40} />
                <h3>Modern Equipment</h3>
                <p>State-of-the-art tools for flawless execution.</p>
              </div>
              <div className="highlight">
                <FaCheckCircle size={40} />
                <h3>Guaranteed Satisfaction</h3>
                <p>Ensuring you're happy with the final result.</p>
              </div>
              <div className="highlight">
                <FaUsers size={40} />
                <h3>Trusted Team Members</h3>
                <p>Professional and reliable experts at your service.</p>
              </div>
            </div>
          </section>

      {/* Call to Action Section */}
      <section className="service-call-to-action">
        <h2>Not ready to talk? Check out our  <a href="/services" style={{color: "blue"}}>services</a></h2>
        <p>Get answers, ideas and recommendations today        </p>
        <a href="/contact" className="category-buttons"><button>Get Started</button> </a>
      </section>

      {/* Our Team Section */}
      <section className="team-section">
        <h2>Meet Our Team</h2>
        <div className="team-grid">
        
          <div className="team-member">
            <img
              src="https://level-contractor.thepreview.pro/images/about-us/Jane-Smith.webp"
              alt="Team Member"
            />
            <h3>Jane Smith</h3>
            <p>Project Manager</p>
          </div>
          <div className="team-member">
            <img
              src="https://level-contractor.thepreview.pro/images/about-us/John-Doe.webp"
              alt="Team Member"
            />
            <h3>John Doe</h3>
            <p>CEO & Founder</p>
          </div>
          <div className="team-member">
            <img
              src="https://level-contractor.thepreview.pro/images/about-us/Emily-Johnson.webp"
              alt="Team Member"
            />
            <h3>Emily Johnson</h3>
            <p>Design Consultant</p>
          </div>
        </div>
      </section>


      {/* FAQ Section */}
      <Questions />
  
    
        <MultiStepForm />
        <Locations />
         

         {/* Other Services Section */}
         <section className="other-services serbox">
        <h2> Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>

    </div>
  );
};

export default AboutUs;
