import "./ServicePage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Questions from "../Faq/Questions";

const ExteriorInterior = () => {


  const navigate = useNavigate();
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "Landscaping",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/6.webp",
      link: "https://level-contractor.thepreview.pro/services/landscaping"
    },
  ];
  return (
    <div className="service-page">
      {/* Back Button */}
      <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      <header className="service-header">
        <img
          src="https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/banner.jpg"
          alt="Beautifully Remodeled Bathroom"
          className="header-image"
        />
        <h1>Interior and Exterior Painting  Services</h1>
        <p>Breathe new life into your home with a fresh coat of paint!        </p>
      </header>

      <section className="service-introduction">
        <div className="section-content">
          <img
            src="https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/why-choose.jpg"
            alt="Introduction to Bathroom Remodeling"
            className="section-image"
          />
          <div className="serve-text">
            <h2>Why choose our Interior and Exterior Painting Services?
            </h2>
            <p>
            At Level Contractor, we specialize in providing top-quality interior painting services that breathe new life into your home. Whether you’re refreshing a single room or updating your entire space, our expert team ensures precise, flawless results every time. We use premium paints and meticulous techniques to deliver a finish that enhances the beauty, comfort, and atmosphere of your home. Our goal is to transform your living space into one that reflects your style and personality. 
            </p>
          </div>

        </div>
      </section>

      {/* Benefits Section with Different Style */}
      <section className="exterior-interior-painting">
        <h2>Benefits of Our Interior and Exterior Remodeling</h2>
        <div className="benefits-grid">
          {[
            { title: "Visually Appealing ", description: "A beautifully painted interior and exterior boosts the overall look of your home, increasing its value and making it stand out in the neighborhood.  " },
            { title: "Expert Preparation", description: "We carefully prepare the surfaces, including cleaning and repairing walls, to ensure a smooth, flawless finish that lasts.  " },
            { title: "Minimal Disruption", description: "Our team works efficiently and respects your home, minimizing disruption and ensuring your space remains clean and protected throughout the project.  " },
            { title: "Weather Protection", description: "We use high-quality, weather-resistant paints that protect your home from the damaging effects of the sun, rain, and wind, ensuring long-lasting results." },
            { title: "Long-Lasting", description: "Using premium paints and proven techniques, we provide a durable, high-quality finish that stands up to everyday wear and tear.  " }
            // { title: "Improved Aesthetics", description: "Create a stylish and inviting atmosphere." }
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <span className="benefit-icon">{benefit.icon}</span>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Service Highlights Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaHammer size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>

      {/* Process Section with Different Style */}
      <section className="service-process">
        <h2>Our Remodeling Process</h2>
        <div className="process-steps">
          {[
            { step: "Consultation", description: "We discuss your vision, needs, and budget." },
            { step: "Design", description: "Our designers create a customized plan for your home." },
            { step: "Material Selection", description: "Choose from high-quality materials and finishes." },
            { step: "Construction", description: "Our skilled team executes the remodel with precision." },
            { step: "Final Walkthrough", description: "We ensure everything meets your expectations before completion." }
          ].map((process, index) => (
            <div key={index} className="process-card">
              <span className="process-icon">🔨</span> {/* Icon for process steps */}
              <div className="process-content">
                <h3>{process.step}</h3>
                <p>{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* New Work Showcase Section */}
      <section className="work-showcase">
        <h2>Our Work Showcase</h2>

        {/* Before Remodeling */}
        <div className="showcase-section">
          <h3>Before Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/Before-${index + 1}.jpg`} alt={`Before Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* In Progress */}
        <div className="showcase-section">
          <h3>In Progress</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/WIP-${index + 1}.jpg`} alt={`In Progress Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* After Remodeling */}
        <div className="showcase-section">
          <h3>After Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/After-${index + 1}.jpg`} alt={`After Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

      </section>

      {/* FAQ Section */}
      <Questions />
      <MultiStepForm />
                 {/* Other Services Section */}
      <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ExteriorInterior;
