import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Services = () => {
  const { href } = useParams();
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true); // State to track image loading

  const placeholderImage = 'https://level-contractor.thepreview.pro//Backend/admin/uploads/hthjhy.webp';

  // Fetch services from the API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://level-contractor.thepreview.pro//Backend/admin/api/service-api.php');
        setServices(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  // Handle service selection and redirect based on href
  useEffect(() => {
    if (services.length > 0) {
      const service = services.find(service => service.href === href) || services[0];
      setSelectedService(service);

      // Redirect to the first service if no href is provided in the URL
      if (!href && service) {
        navigate(`/services/${service.href}`, { replace: true });
      }

      // Scroll to 170px when the component mounts or the href changes
      window.scrollTo({
        top: 170,
        behavior: 'smooth',
      });
    }
  }, [href, services, navigate]);

  // Preload the image for smoother transitions
  useEffect(() => {
    if (selectedService && selectedService.image) {
      const img = new Image();
      img.src = `https://level-contractor.thepreview.pro//Backend/admin/uploads/${selectedService.image}`;
      img.onload = () => setImageLoading(false);
    }
  }, [selectedService]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!selectedService) {
    return <div>Service not found</div>;
  }

  return (
    <div id="qodef-page-outer">
      <div id="qodef-page-inner" className="qodef-content-full-width">
        <main id="qodef-page-content" className="qodef-grid qodef-layout--template" role="main">
          <div className="qodef-grid-inner clear">
            <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
              <div className="elementor elementor-3070">
                {/* First Section */}
                <section
                  className="ser-first elementor-section elementor-top-section elementor-element elementor-section-full_width elementor-section-height-default"
                  style={{ backgroundColor: '#a9b1ab' }}
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100">
                      <div className="elementor-widget-wrap">
                        <h1>Services</h1>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Second Section */}
                <section
                  className="ser-second elementor-section elementor-top-section elementor-section-full_width elementor-section-height-default"
                  style={{ backgroundColor: '#a9b1ab' }}
                >
                  <div className="service-detail-page">
                    <div className="sidebar">
                      <ul>
                        {services.map(service => (
                          <li key={service.id} className="category-buttonsgl">
                            <Link to={`/services/${service.href}`} className={href === service.href ? 'active' : ''}>
                              {service.heading}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="service-content">
                      {imageLoading ? (
                        <div className="shimmer-placeholder">
                          <img src={placeholderImage} alt="Loading..." className="placeholder-image" />
                        </div>
                      ) : (
                        <img
                          src={`https://level-contractor.thepreview.pro//Backend/admin/uploads/${selectedService.image}`}
                          alt={selectedService.heading}
                          title={selectedService.heading}
                          className="ser-banner-image"
                          loading="lazy"
                        />
                      )}
                      <h2>{selectedService.heading}</h2>
                      <p>{selectedService.des}</p>
                      <div className="category-buttons ser-cont">
                        <Link to={`/${selectedService.href}`} className={href === selectedService.href ? 'active' : ''}>
                          <button>Read More</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Services;
