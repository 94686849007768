import React, { useState, useEffect } from 'react';
import ContactForm from './ContactForm';

const HomeContact = () => {
  const [homecontact, setHomecontact] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/home-contactsec-api.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.error) {
          setError(data.error);
        } else {
          setHomecontact(data);
          setError(null);
        }
      })
      .catch(error => {
        setError(error.message);
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      <div className="elementor-container elementor-column-gap-default">
        {error && <div className="alert alert-danger">{error}</div>}

        {homecontact.map((contact, index) => (
          <div key={index} className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3e77b4a" data-id="3e77b4a" data-element_type="column">
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-69bb51b elementor-widget elementor-widget-level_core_icon_with_text"
                data-id="69bb51b"
                data-element_type="widget"
                data-widget_type="level_core_icon_with_text.default"
              >
                <div className="elementor-widget-container">
                  <div className="qodef-shortcode qodef-m qodef-icon-with-text qodef-layout--before-content qodef--icon-pack">
                    <div className="qodef-m-icon-wrapper">
                      <span
                        className="qodef-shortcode qodef-m qodef-icon-holder qodef-size--default qodef-layout--circle"
                        style={{
                          width: 4,
                          height: 4,
                          lineHeight: 4,
                        }}
                      ></span>
                    </div>
                    <div className="qodef-m-content">
                      <h6
                        className="qodef-m-title"
                        style={{ marginTop: '-5px' }}
                      >
                        <span className="qodef-m-title-text">
                          {contact.heading}
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="elementor-element elementor-element-50ebd58 elementor-widget elementor-widget-level_core_section_title"
                data-id="50ebd58"
                data-element_type="widget"
                data-widget_type="level_core_section_title.default"
              >
                <div className="elementor-widget-container">
                  <div className="qodef-shortcode qodef-m qodef-section-title qodef-alignment--left">
                    <h2 className="qodef-m-title">
                      {contact.content}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c7866c6"
          data-id="c7866c6"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-35fe2ac elementor-widget elementor-widget-wp-widget-level_core_contact_form_7"
              data-widget_type="wp-widget-level_core_contact_form_7.default"
            >
              <div className="elementor-widget-container">
                <div className="qodef-contact-form-7">
                  <div
                    className="wpcf7 no-js"
                    id="wpcf7-f1387-p1303-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
