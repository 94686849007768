import "./ServicePage.css";
import MultiStepForm from "../MultiStepForm/MultiStepForm";
import { FaHammer, FaTools, FaCheckCircle, FaUsers } from "react-icons/fa";
import Questions from "../Faq/Questions";
import { Link, useNavigate } from "react-router-dom";

const Landscaping = () => {

  const navigate = useNavigate();
  const services = [
    {
      title: "Kitchen Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/1.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/kitchen-remodeling"
    },
    {
      title: "Bathroom Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/2.webp",
      link: "https://level-contractor.thepreview.pro/services/bathroom-remodeling"
    },
    {
      title: "Outdoor Patio Remodeling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/3.webp",
      link: "https://level-contractor.thepreview.pro/services/outdoor-patio-remodeling"
    },
    {
      title: "Roofing",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/4.webp",
      link: "https://level-contractor.thepreview.pro/services/roofing"
    },
    {
      title: "Home Remodelling",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/5.webp",
      link: "https://level-contractor.thepreview.pro/services/home-remodeling"
    },
    {
      title: "New Home Construction",
      image: "https://level-contractor.thepreview.pro/images/project-page-services/7.webp", // Replace with your actual image URL
      link: "https://level-contractor.thepreview.pro/services/new-home-construction"
    }
  ];
  return (
    <div className="service-page">
      {/* Back Button */}
      <span className="category-buttons">
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
      >
        ← Back to Previous Page
      </button>
      </span>
      <header className="service-header">
        <img
          src="https://level-contractor.thepreview.pro/images/services/landscaping/banner.jpg"
          alt="Beautifully Remodeled Bathroom"
          className="header-image"
        />
        <h1>Landscaping Services</h1>
        <p>Make your dream of a luxury home come true with Level Contractor!        </p>
      </header>

      <section className="service-introduction">
        <div className="section-content">
          <img
            src="https://level-contractor.thepreview.pro/images/services/landscaping/why-choose.jpg"
            alt="Introduction to Landscaping"
            className="section-image"
          />
          <div className="serve-text">
            <h2>Why Choose Our Landscaping Services?</h2>
            <p>
            At Level Contractor, we believe that a well-designed landscape enhances the beauty, functionality, and value of your home. Whether you're looking to create a lush garden, a functional outdoor living space, or an inviting front yard, our team of experts is committed to delivering high-quality, customized landscaping solutions that meet your needs and exceed your expectations. We work closely with you to bring your vision to life, providing professional design, expert installation, and ongoing care. 
            </p>
          </div>

        </div>
      </section>

      {/* Benefits Section with Different Style */}
      <section className="landscaping">
        <h2>Benefits of Landscaping Services</h2>
        <div className="benefits-grid">
          {[
            
            { title: "Increased Home Value", description: "A well-landscaped yard not only enhances your home's beauty but also boosts its market value, making it more attractive to potential buyers." },
            { title: "Expert Craftsmanship", description: "From planting to hardscaping, our team has the expertise to deliver top-quality workmanship, ensuring your landscape looks great and functions flawlessly." },
            { title: "Ongoing Maintenance", description: "We offer maintenance services to keep your landscape in pristine condition, from seasonal clean-ups to regular upkeep, so your outdoor space always looks its best." },
            { title: "Custom Designs", description: "We create tailored landscapes that perfectly match your style, preferences, and needs, ensuring a beautiful and functional outdoor space." },
            { title: "Sustainability", description: "We incorporate sustainable materials in your landscaping project, creating an environmentally conscious outdoor space that’s both beautiful and low-maintenance. " }
            // { title: "Improved Aesthetics", description: "Create a stylish and inviting atmosphere." }
          ].map((benefit, index) => (
            <div key={index} className="benefit-card">
              <span className="benefit-icon">{benefit.icon}</span>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Service Highlights Section */}
      <section className="highlights-section">
        <h2>Why Work With Us</h2>
        <div className="highlights-grid">
          <div className="highlight">
            <FaHammer size={40} />
            <h3>Expert Craftsmanship</h3>
            <p>Precision and quality in every detail.</p>
          </div>
          <div className="highlight">
            <FaTools size={40} />
            <h3>Modern Equipment</h3>
            <p>State-of-the-art tools for flawless execution.</p>
          </div>
          <div className="highlight">
            <FaCheckCircle size={40} />
            <h3>Guaranteed Satisfaction</h3>
            <p>Ensuring you're happy with the final result.</p>
          </div>
          <div className="highlight">
            <FaUsers size={40} />
            <h3>Trusted Team Members</h3>
            <p>Professional and reliable experts at your service.</p>
          </div>
        </div>
      </section>

      {/* Process Section with Different Style */}
      <section className="service-process">
        <h2>Our Remodeling Process</h2>
        <div className="process-steps">
          {[
            { step: "Consultation", description: "We discuss your vision, needs, and budget." },
            { step: "Design", description: "Our designers create a customized plan for your home." },
            { step: "Material Selection", description: "Choose from high-quality materials and finishes." },
            { step: "Construction", description: "Our skilled team executes the remodel with precision." },
            { step: "Final Walkthrough", description: "We ensure everything meets your expectations before completion." }
          ].map((process, index) => (
            <div key={index} className="process-card">
              <span className="process-icon">🔨</span> {/* Icon for process steps */}
              <div className="process-content">
                <h3>{process.step}</h3>
                <p>{process.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* New Work Showcase Section */}
      <section className="work-showcase">
        <h2>Our Work Showcase</h2>

        {/* Before Remodeling */}
        <div className="showcase-section">
          <h3>Before Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/landscaping/Before-${index + 1}.jpg`} alt={`Before Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* In Progress */}
        <div className="showcase-section">
          <h3>In Progress</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/landscaping/WIP-${index + 1}.jpg`} alt={`In Progress Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

        {/* After Remodeling */}
        <div className="showcase-section">
          <h3>After Remodeling</h3>
          <div className="gallery-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div key={index} className="gallery-item">
                <img src={`https://level-contractor.thepreview.pro/images/services/landscaping/After-${index + 1}.jpg`} alt={`After Bathroom ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>

      </section>

      {/* FAQ Section */}
      <Questions />

      <MultiStepForm />
     {/* Other Services Section */}
     <section className="other-services">
        <h2>Other Services We Provide</h2>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
           <Link to={service.link}>
              <h3>{service.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Landscaping;
