import React from 'react'
import ContactForm from './component/ContactForm'

const Contact = () => {
  return (
    <div id='qodef-page-outer'>
      <div id='qodef-page-inner' className='qodef-content-full-width'>
        <main
          id='qodef-page-content'
          className='qodef-grid qodef-layout--template'
          role='main'
        >
          <div className='qodef-grid-inner clear'>
            <div className='qodef-grid-item qodef-page-content-section qodef-col--12'>
              <div className='elementor elementor-3070'>
                {/* Contact Us Section */}
                <section className='ser-second elementor-section elementor-section-full_width'>
                  <div className='elementor-container mob-pdt-50' >
                    <div className='elementor-column elementor-col-50'>
                      <div className='elementor-widget-wrap'>
                        {/* Section Title */}
                        <div className='qodef-section-title qodef-alignment--left cont-head'>
                          <h1 className='qodef-m-title'>Contact Us</h1>
                          <p className='qodef-m-text'>
                            If you have any questions or queries related to our
                            services, please feel free to contact us. Fill{' '}
                            <br />
                            out the form below, and we will get back to you as
                            soon as possible.
                          </p>
                        </div>
                        {/* Contact Form */}
                        <div className='qodef-contact-form-7 mob-mrb-50'>
                          <ContactForm />
                        </div>
                      </div>
                    </div>
                    <div className='elementor-column elementor-col-50 flex-space-evenly cont-head'>
                      <div className='flex-main'>
                        {/* Direct Contact */}
                        <div className='qodef-section-title qodef-alignment--left mr-30'>
                          <div>
                            <h6 className='qodef-m-title mrb-20'>
                              Direct Contact
                            </h6>
                          </div>
                          <p>
                            <a
                              href='https://www.google.com/maps/place/123+Terra+Street,+Copenhagen'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              3494 Camino Tassajara <br /> #5212 Danville, Ca
                            </a>
                          </p>
                          <p>
                            <a href='tel:+18182972920'>+1 818-297-2920</a>
                          </p>
                          <p>
                            <a href='mailto:levelcontractor.office@gmail.com'>
                              levelcontractor.office@gmail.com
                            </a>
                          </p>
                        </div>

                        <div className='contact-hour'>
                          <div className='qodef-section-title qodef-alignment--left '>
                            <h6 className='qodef-m-title '>Business Hours</h6>
                          </div>
                          <p>Mon to Fri</p>
                          <p>
                            9 AM - 5 PM
                            {/* <br />
                            Saturday - Sunday Closed */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* Office Locations Section */}
                <section className=' elementor-section elementor-section-full_width'>
                  <div style={{ width: '100%', height: '450px' }}>
                    <iframe
                      title='Google Map'
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.983810362701!2d-121.91434720000001!3d37.76697779999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fedf094443745%3A0x3b540689fc3c079a!2s2125%20Chamomile%20Ct%2C%20San%20Ramon%2C%20CA%2094582%2C%20USA!5e0!3m2!1sen!2sin!4v1724693732533!5m2!1sen!2sin'
                      height='450'
                      style={{ border: 0 }}
                      allowFullScreen=''
                      loading='lazy'
                      referrerPolicy='no-referrer-when-downgrade'
                    ></iframe>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Contact
