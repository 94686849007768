import React, { useState } from "react";
import "./ReviewPage.css";

const ReviewPage = () => {
  // Dummy review data
  const initialReviews = [
    {
      id: 1,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/1.webp", // Replace with user photo URL
      name: "Emily Johnson",
      date: "Dec 1, 2024",
      rating: 5,
      title: "Exceptional Service!",
      comment: "The team was professional, timely, and did an outstanding job on my kitchen remodel. Highly recommended!",
    },
    {
      id: 2,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/2.webp",
      name: "Michael Smith",
      date: "Nov 25, 2024",
      rating: 4,
      title: "Great Experience",
      comment: "They transformed my living room beautifully. The only issue was a slight delay in timeline, but the result was worth it.",
    },
    {
      id: 3,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/3.webp",
      name: "Sophia Lee",
      date: "Nov 15, 2024",
      rating: 5,
      title: "Amazing Work!",
      comment: "I absolutely love my new bathroom. The attention to detail and quality of work exceeded my expectations.",
    },
    {
      id: 4,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/4.webp",
      name: "David Brown",
      date: "Oct 30, 2024",
      rating: 4,
      title: "Worth It",
      comment: "The team was courteous and knowledgeable. My backyard has never looked better. Will hire them again!",
    },
    {
      id: 5,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/5.webp",
      name: "Olivia Garcia",
      date: "Oct 20, 2024",
      rating: 5,
      title: "Highly Recommended",
      comment: "Their service was impeccable, and my new home addition turned out exactly as I envisioned.",
    },

    {
      id: 6,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/6.webp",
      name: "Victor Brown",
      date: "Apr 15, 2024",
      rating: 5,
      title: "Excellent Work",
      comment: "Their service was impeccable, and my new home addition turned out exactly as I envisioned.",
    },
    {
      id: 7,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/4.webp",
      name: "David Brown",
      date: "Oct 30, 2024",
      rating: 4,
      title: "Worth It",
      comment: "The team was courteous and knowledgeable. My backyard has never looked better. Will hire them again!",
    },
    {
      id: 8,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/5.webp",
      name: "Olivia Garcia",
      date: "Oct 20, 2024",
      rating: 5,
      title: "Highly Recommended",
      comment: "Their service was impeccable, and my new home addition turned out exactly as I envisioned.",
    },

    {
      id: 9,
      photo: "https://level-contractor.thepreview.pro/images/client-reviews/6.webp",
      name: "Victor Brown",
      date: "Apr 15, 2024",
      rating: 5,
      title: "Excellent Work",
      comment: "Their service was impeccable, and my new home addition turned out exactly as I envisioned.",
    }
  ];

  const [reviews, setReviews] = useState(initialReviews.slice(0, 3));
  const [hasMore, setHasMore] = useState(true);

  const loadMoreReviews = () => {
    const nextReviews = initialReviews.slice(reviews.length, reviews.length + 3);
    setReviews([...reviews, ...nextReviews]);

    if (reviews.length + nextReviews.length >= initialReviews.length) {
      setHasMore(false);
    }
  };

  const [newReview, setNewReview] = useState({
    photo: "",
    name: "",
    rating: "",
    title: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newReview.name || !newReview.rating || !newReview.title || !newReview.comment) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    const newReviewData = {
      ...newReview,
      id: reviews.length + 1,
      photo: "https://via.placeholder.com/60", // Placeholder photo
      date: new Date().toLocaleDateString(),
    };

    setReviews([newReviewData, ...reviews]);
    setNewReview({ photo: "", name: "", rating: "", title: "", comment: "" });
    alert("Thank you for your review!");
  };

  return (
    <div className="review-container">
      <h1>Customer Reviews</h1>

      {/* Reviews Grid */}
      <div className="review-grid">
        {reviews.map((review) => (
          <div key={review.id} className="review-card">
            <img src={review.photo} alt={review.name} className="user-photo" />
            <div className="review-content">
              <h3>{review.name}</h3>
              <p className="review-date">{review.date}</p>
              <div className="rating">
                {"⭐".repeat(review.rating)}{" "}
                <span>({review.rating}/5)</span>
              </div>
              <h4>{review.title}</h4>
              <p>{review.comment}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      {hasMore && (        
        <button className="load-more" onClick={loadMoreReviews}>
          Load More
        </button>
      )}

      {/* Add New Review */}
      <div className="add-review">
        <h2>Submit Your Review</h2>
        <form onSubmit={handleSubmit} className="review-form">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={newReview.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Rating:
            <select
              name="rating"
              value={newReview.rating}
              onChange={handleChange}
              required
            >
              <option value="">Select a rating</option>
              <option value="1">1 - Poor</option>
              <option value="2">2 - Fair</option>
              <option value="3">3 - Good</option>
              <option value="4">4 - Very Good</option>
              <option value="5">5 - Excellent</option>
            </select>
          </label>
          <label>
            Review Title:
            <input
              type="text"
              name="title"
              value={newReview.title}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Comment:
            <textarea
              name="comment"
              value={newReview.comment}
              onChange={handleChange}
              required
            ></textarea>
          </label>
          <span className="category-buttons"><button type="submit">Submit Review</button></span>
          
        </form>
      </div>
    </div>
  );
};

export default ReviewPage;
