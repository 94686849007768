import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import AlertModal from '../AlertModal';

function ContactForm() {
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Message, setMessage] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    // Send email and save data to the server
    function Sendmail(e) {
        e.preventDefault(); // Prevent form from refreshing the page

        const Data = {
            to_name: Name,
            to_email: Email,
            message: Message
        };

        // Send email via EmailJS
        const Service_id = "service_hxu03qa";
        const Template_id = "template_i9wsdd5";
        const User_id = "F7mGvyYtAk2zFgJhY";

        emailjs.send(Service_id, Template_id, Data, User_id)
            .then(
                function (response) {
                    console.log('Email sent successfully:', response);
                    setAlertMessage("Message Sent Successfully!");
                    sendDataToServer(Data); // Call function to send data to PHP server
                },
                function (error) {
                    console.log('Email send error:', error);
                    setAlertMessage("Error sending message. Please try again.");
                }
            );
    }

    // Function to send form data to PHP server
    function sendDataToServer(Data) {
        fetch("https://level-contractor.thepreview.pro/Backend/admin/api/form-api.php", { // Replace with your PHP API URL
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(Data)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Server response:', data);
            if (data.status === 'success') {
                setAlertMessage("Message and data saved successfully!");
            } else {
                setAlertMessage("Error saving form data.");
            }
            setShowAlert(true); // Show the alert modal
            resetForm(); // Reset form fields
        })
        .catch(error => {
            console.error("Error sending data to the server", error);
            setAlertMessage("Error saving form data.");
            setShowAlert(true); // Show the alert modal
        });
    }

    // Function to reset the form after successful submission
    function resetForm() {
        setName("");    // Clear the Name field
        setEmail("");   // Clear the Email field
        setMessage(""); // Clear the Message field
    }

    // Close the alert modal
    function closeAlert() {
        setShowAlert(false);
    }

    return (
        <>
            <form className="wpcf7-form init demo" onSubmit={Sendmail}>
                <div className="qodef-contact-form">
                    <div className="qodef-grid qodef-layout--columns qodef-col-num--2">
                        <div className="qodef-grid-inner">
                            <div className="qodef-grid-item">
                                <span className="wpcf7-form-control-wrap">
                                    <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text"
                                        placeholder="Name"
                                        type="text"
                                        name="your-name"
                                        required
                                        value={Name}
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </span>
                            </div>
                            <div className="qodef-grid-item">
                                <span className="wpcf7-form-control-wrap" data-name="your-email">
                                    <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-email"
                                        placeholder="Email"
                                        type="email"
                                        required
                                        name="your-email"
                                        value={Email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <span className="wpcf7-form-control-wrap" data-name="your-message">
                        <textarea
                            cols={40}
                            rows={5}
                            required
                            className="wpcf7-form-control wpcf7-textarea"
                            placeholder="Message"
                            name="your-message"
                            value={Message}
                            onChange={(e) => { setMessage(e.target.value) }}
                        />
                    </span>
                    <button
                        className="wpcf7-form-control wpcf7-submit qodef-button qodef-size--normal qodef-m"
                        type="submit"
                    >
                        <span className="qodef-m-text">Send</span>
                    </button>
                </div>
            </form>

            {showAlert && <AlertModal message={alertMessage} onClose={closeAlert} />}
        </>
    );
}

export default ContactForm;
