import { Link } from 'react-router-dom'

const ServiceList = () => {
  return (
    <div className="hom-mid-banner">
    <div className="mid-banner-head">
      <h3>At Level Contractor</h3>
      <h5>
        We specialize in providing exceptional home construction and remodeling
        services in California.
      </h5>
      <div className="mid-banner-service">
        <ul>
          <li><Link to="/services">Home Remodeling</Link></li>
          <li><Link to="/services/kitchen-remodeling">Kitchen Remodeling</Link></li>
          <li><Link to="/services/bathroom-remodeling">Bathroom Remodeling</Link></li>
          <li><Link to="/services/room-additions">Room Additions</Link></li>
          <li><Link to="/services/adu">ADU</Link></li>
          <li><Link to="/services/garage-conversions">Garage Conversions</Link></li>
          <li><Link to="/services/exterior-interior-painting">Exterior & Interior Painting</Link></li>
        </ul>
        <ul>
          <li><Link to="/services/roofing">Roofing</Link></li>
          <li><Link to="/services/landscaping">Landscaping</Link></li>
          <li><Link to="/services/window-door-installation">Window & Door Installation</Link></li>
          <li><Link to="/services/3d-build">3D Build Architecture </Link></li>
          <li><Link to="/services/concrete-driveways">Concrete & Driveways</Link></li>
          <li><Link to="/services/outdoor-patio-remodeling">Outdoor Patio Remodeling</Link></li>
          <li><Link to="/services/new-home-construction">New Home Construction</Link></li>
        </ul>
      </div>
    </div>
    <div className='category-buttons ser-cont mid-btn'>
      <Link to="/contact"><button>Contact Us</button></Link>
    </div>
  </div>
  )
}

export default ServiceList