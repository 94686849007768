import React, { useState, useEffect } from 'react';

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filteredWorks, setFilteredWorks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    fetch('https://level-contractor.thepreview.pro/Backend/admin/api/our-work-api.php')
      .then(response => response.json())
      .then(data => {
        setWorks(data);
        setFilteredWorks(data);
        
        // Extract unique categories from the data
        const uniqueCategories = ["All", ...new Set(data.map(item => item.category))];
        setCategories(uniqueCategories);

        // Preload images for initial render (optional)
        preloadImages(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const preloadImages = (workItems) => {
    workItems.forEach(work => {
      new Image().src = work.before_image;
      new Image().src = work.after_image;
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const filtered = category === "All" ? works : works.filter(work => work.category === category);
    setFilteredWorks(filtered);

    // Preload images for the selected category
    preloadImages(filtered);
  };

  return (
    <div className='work-page'>
      <h2>Our Work</h2>
      {/* Render Category Buttons */}
      <div className="category-buttons">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? 'active' : ''}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Display Filtered Works */}
      <div className="work-gallery">
        {filteredWorks.map((work) => (
          <div key={work.id} className="work-item">
            {/* <BeforeAfterSlider beforeImage={work.before_image} afterImage={work.after_image} /> */}
            <img src={work.after_image} alt={work.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
