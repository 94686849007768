import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BlogDetailPage.css';

const BlogDetailPage = () => {
  const { slug } = useParams(); // Capture slug from URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://level-contractor.thepreview.pro///Backend/admin/api/blogs-api.php`);
        
        // Check if the API returns all blogs, filter by slug manually
        const blogData = response.data.find(b => b.slug === slug);

        if (blogData) {
          setBlog(blogData); // Set the blog data that matches the slug
        } else {
          setError('No blog found for this slug.');
        }
      } catch (error) {
        setError('Failed to load blog content.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]); // Run this effect whenever slug changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!blog) {
    return <div>No blog found.</div>;
  }

  return (
    <div className="blog-detail-page">
      <h1 className="blog-detail-title">{blog.title}</h1>
      <div className="blog-detail-meta">
        <span className="blog-category">{blog.category}</span> | 
        <span className="blog-date">{new Date(blog.created_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long', // Use '2-digit' for numeric month
    day: '2-digit'
  })}</span>
      </div>
      <img 
        src={`https://level-contractor.thepreview.pro/Backend/admin/uploads/${blog.image}`} 
        alt={blog.title} 
        className="blog-detail-image" 
      />
      <div className="blog-detail-content">
        <p dangerouslySetInnerHTML={{ __html: blog.content }}></p> {/* Render HTML safely */}
      </div>
    </div>
  );
};

export default BlogDetailPage;
